export const API_CONFIG = {
  baseURL: 'https://api-core-demo.stand-ftw.tech',
  socketURL: 'https://api-core-demo.stand-ftw.tech',
};
/*
export const API_CONFIG = {
  baseURL: 'http://192.168.0.30:3001',
  socketURL: 'http://192.168.0.30:3001',
};
*/

export const ASSETS_CONFIG = {
  baseURL: 'assets/mocks',
}
