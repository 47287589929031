export const IMAGE_DIR = 'stored-images';
export const MODE = 'PRD';
export const SYSTEM_NAME = 'home.title-welcome';
export const SYSTEM_NICKNAME = 'Stand FTW ®';
export const SYSTEM_VERSION = '1.0.0';
export const SYSTEM_LOGO='assets/imgs/logos/Stand FTW - Icon - NO BG.png';
export const SYSTEM_INFO = {
    MODE,
    SYSTEM_NICKNAME,
    SYSTEM_NAME,
    SYSTEM_VERSION,
    SYSTEM_LOGO,
};